import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px 30px;

  ${atMinWidth.md`
    display: flex;
    flex-flow: row nowrap
  `}

  > div.second-column-wrapper {
    display: contents;

    ${atMinWidth.md`
      display: block;
      width: 100%;
    `}

    h2 {
      grid-row-start: 1;
      ${font('display', 'md', '700')};
      color: ${colors.gray[900]};

      ${atMinWidth.lg`
        ${font('display', 'lg', '700')};
      `};
    }
  }
`;

export default Wrapper;
