import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FilterProvider } from '../../contexts/filterContext';
import Section from '../../molecules/Section/Section';
import { colors } from '../../styles/atoms/colors';
import Filter from './Filter/Filter';
import TestimonialList from './TestimonialList/TestimonialList';
import Wrapper from './TestimonialListing.styles';

const TestimonialListing = () => {
  const {
    allDatoCmsComponentTestimonial,
    datoCmsComponentTestimonialListing,
  } = useStaticQuery(graphql`
    query allDatoCmsComponentTestimonial {
      allDatoCmsComponentTestimonial(
        filter: { notOnReviewsListingsPage: { eq: false } }
      ) {
        nodes {
          ...datoCmsComponentTestimonial
        }
      }
      datoCmsComponentTestimonialListing {
        heading
      }
    }
  `);

  return (
    <Section bgColor={colors.gray[50]}>
      <FilterProvider>
        <Wrapper>
          {allDatoCmsComponentTestimonial?.nodes.length > 0 && (
            <>
              <Filter />
              <div className="second-column-wrapper">
                {datoCmsComponentTestimonialListing.heading && (
                  <h2>{datoCmsComponentTestimonialListing.heading}</h2>
                )}
                <TestimonialList
                  testimonials={allDatoCmsComponentTestimonial}
                  pageLimit={10}
                />
              </div>
            </>
          )}
        </Wrapper>
      </FilterProvider>
    </Section>
  );
};

export default TestimonialListing;
